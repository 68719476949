import "slick-carousel";
import "slick-carousel/slick/slick.css";

import $ from "jquery";

var $bookSlider = $(".slider.books");

$bookSlider.slick({
  dots: false,
  infinite: true,
  speed: 500,
  cssEase: "linear",
  nextArrow:
    '<button class="slick-next"><svg class="w-6 h-6 block fill-current text-white" viewBox="0 0 14 25"><path d="M1.415 24.54a1.392 1.392 0 01-.984-2.377l9.416-9.425L.43 3.317a1.39 1.39 0 111.968-1.968l11.386 11.389L2.399 24.132a1.387 1.387 0 01-.984.408"/></svg></button>',
  prevArrow:
    '<button class="slick-prev"><svg class="w-6 h-6 block fill-current text-white" viewBox="0 0 15 25"><path d="M13.152 24.54c-.356 0-.712-.136-.984-.408L.78 12.742 12.168 1.35a1.39 1.39 0 111.968 1.968l-9.417 9.426 9.417 9.42a1.392 1.392 0 01-.984 2.377"/></svg></button>',
  slidesToShow: 6,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4
      }
    },
    {
      breakpoint: 640,
      settings: {
        slidesToScroll: 1,
        slidesToShow: 2
      }
    }
  ]
});

var $filmSlider = $(".slider.films");

$filmSlider.slick({
  dots: false,
  infinite: true,
  speed: 500,
  cssEase: "linear",
  nextArrow:
    '<button class="slick-next"><svg class="w-6 h-6 block fill-current text-white" viewBox="0 0 14 25"><path d="M1.415 24.54a1.392 1.392 0 01-.984-2.377l9.416-9.425L.43 3.317a1.39 1.39 0 111.968-1.968l11.386 11.389L2.399 24.132a1.387 1.387 0 01-.984.408"/></svg></button>',
  prevArrow:
    '<button class="slick-prev"><svg class="w-6 h-6 block fill-current text-white" viewBox="0 0 15 25"><path d="M13.152 24.54c-.356 0-.712-.136-.984-.408L.78 12.742 12.168 1.35a1.39 1.39 0 111.968 1.968l-9.417 9.426 9.417 9.42a1.392 1.392 0 01-.984 2.377"/></svg></button>',
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1
      }
    }
  ]
});
